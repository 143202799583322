import * as React from "react"

import { Link } from 'gatsby'

import { StaticImage } from "gatsby-plugin-image"

import Page from '../../components/Page'

const BlogSinglepage = () => {

    return (
        <>
            <Page>
                <div className="relative">
                    <StaticImage src="../../assets/images/blogsingle-hero.png" alt="" className="w-full md:block hidden" />
                    <div className="max-w-7xl mx-auto px-4 py-20">
                        <div className="w-full grid md:grid-cols-4 grid-cols-1 gap-10">
                            <div className="w-2/4">
                                <h2 className="text-2xl w-full mb-2">Machine learning</h2>
                                <div className="flex flex-row justify-between mt-10 w-full">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="28" viewBox="100.001 529 32 28"><path d="M102.855 540.324a5.963 5.963 0 0 1-1.854-4.328c0-3.31 2.696-5.996 6.023-5.996a6.023 6.023 0 0 1 5.593 3.768 6.032 6.032 0 0 1 5.597-3.768c3.327 0 6.025 2.686 6.025 5.996 0 2.016-1 3.799-2.533 4.886L112.653 550l-9.798-9.676Z" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#3c4fe0" fill="transparent" data-name="Union 6" /></svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="28" viewBox="204 529 32 28"><path d="M207.926 545.845H205V530h21.498v15.845h-8.878L206.501 551l1.425-5.155Z" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#3c4fe0" fill="transparent" data-name="Union 5" /></svg>
                                </div>
                            </div>
                            <div className="col-span-3">
                                <div className="page-content w-full">
                                    <div className="lg:w-3/4 w-full">
                                        <h1>Ship a ML model in days, not months</h1>
                                        <div className="flex flex-row gap-10 items-center mb-4">
                                            <p className="text-gray-400 text-sm flex items-center mr-3"><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                            </svg> -27th Oct</p>
                                            <p className="text-gray-400 text-sm flex items-center"><svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-6 mr-2" viewBox="332 487.95 24 28"><g data-name="Group 245"><path d="M340.097 491.448v6.785h4.777" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#bbb" fill="transparent" data-name="Path 193" /><path d="M332 497.405a9.5 9.5 0 1 1 0 .09z" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.80952" stroke="#bbb" fill="transparent" data-name="Ellipse 57" /></g></svg> -5min read</p>
                                        </div>
                                        <h4>In this article, you’ll learn how we quickly delivered a machine learning service for our clients, along with some tips to enhance collaboration between developers and data scientists.</h4>
                                        <p>Lorem ipsum dolor sit amet, <a href='#'>consectetur adipiscing </a>elit. Morbi blandit massa massa, non aliquet sem facilisis id. Duis dignissim finibus eros vitae lacinia. Sed cursus sapien felis. Proin molestie ante id ligula fringilla vehicula. Cras commodo nisl vitae lacinia volutpat. Nunc eu vulputate ante, sit amet ullamcorper ipsum. Aenean sagittis, nulla at pharetra euismod, enim urna mattis lacus, et eleifend ante ante vel lorem. Vestibulum fringilla sollicitudin ornare. Mauris eu felis sem. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Curabitur laoreet dolor pulvinar tempor mattis. In ac accumsan diam.</p>

                                        <p>Maecenas sodales sagittis turpis vel convallis. <a href='#'>Morbi tempor nisl</a> vitae mi mollis, at tincidunt ante volutpat. Curabitur posuere vitae augue ac iaculis. Aliquam fermentum erat non lacus fringilla, at convallis diam ornare. Praesent odio ligula, dictum in nisi a, mollis mattis felis. Etiam facilisis sapien vitae malesuada pharetra. Vivamus sed ipsum nec lacus sodales ultricies. Nam sagittis, ante eget suscipit imperdiet, nunc urna porta purus, porttitor aliquet ipsum est nec nisi. Nulla ornare id ipsum varius ornare. Curabitur non cursus erat. Phasellus tincidunt quam justo, a consectetur augue ornare sit amet. Quisque ac velit aliquam, vehicula quam at, dapibus augue.</p>
                                        <h3> quickly delivered a machine</h3>
                                        <ul>
                                            <li>Ship a ML model in days</li>
                                            <li> sagittis turpis vel</li>
                                            <li> Morbi temporlMorbi tempor</li>
                                        </ul>

                                        <p>Etiam placerat leo in est laoreet rhoncus. Aenean vehicula scelerisque ipsum et feugiat. Vivamus eu lectus eget augue hendrerit mattis quis nec purus. Aliquam vulputate gravida neque, id mollis est viverra at. In egestas quam nisi. Curabitur sodales diam ac eros blandit commodo. Aenean quis urna consectetur, lacinia enim quis, pulvinar metus. Sed sed interdum erat. Nullam quis nisl tellus. Fusce congue nisl sit amet aliquet ultricies. Quisque quis massa tincidunt, viverra justo ac, tempus diam. Donec eu maximus elit. Pellentesque faucibus nunc lacinia est luctus hendrerit. Nulla eu orci non erat malesuada luctus ac nec ante. Aliquam ultrices tellus quis consequat rutrum.
                                        </p>
                                        <StaticImage src="../../assets/images/blogsingle-hero.png" alt="" className="w-full" />
                                        <p>Aliquam feugiat pharetra pharetra. Ut vitae lorem ipsum. Maecenas faucibus magna in metus elementum semper. Phasellus id finibus velit. Fusce non malesuada justo. Suspendisse vitae nunc non urna condimentum facilisis. Praesent consequat tellus non ipsum sollicitudin, eget dapibus ante tempus.</p>

                                        <div className="w-100 bg-gray-100 sm:p-6 p-4 flex sm:flex-row flex-col items-center my-10 blockquote rounded-xl">
                                            <div className="sm:w-1/4 w-full">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="#d1d5db" className="mx-auto" viewBox="0 0 24 24"><path d="M14.017 21v-7.391c0-5.704 3.731-9.57 8.983-10.609l.995 2.151c-2.432.917-3.995 3.638-3.995 5.849h4v10h-9.983zm-14.017 0v-7.391c0-5.704 3.748-9.57 9-10.609l.996 2.151c-2.433.917-3.996 3.638-3.996 5.849h3.983v10h-9.983z" /></svg>
                                            </div>
                                            <div className="sm:w-3/4 w-full">
                                                <p>Lorem ipsum dolor sit a commodo nisl vitae lacinia volutpat. Nunc eu vulputate ante, sit amet ullamcorper ipsum. Aenean sagittis, nulla at pharetra euismod, enim urna mattis lacus, et eleifend ante ante vel lorem. Vestibulum fringilla sollicitudin ornare. Mautur laoreet dolor pulvinar tempor mattis. In ac accumsan diam.</p>
                                            </div>
                                        </div>
                                        <p>Nam congue lectus dolor, ac laoreet magna tincidunt sed. Nullam sit amet nulla vitae urna varius consectetur. Sed vitae magna consectetur, pharetra nisi ut, vestibulum purus. Nulla luctus nisl sed lectus consequat porttitor. Suspendisse id tempor nibh, id luctus nisi. Ut ultrices ante a imperdiet hendrerit. Curabitur dui mi, sagittis eu varius eu, tempor ornare quam. Donec quis vestibulum dui. Aliquam erat volutpat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. </p>

                                        <h4>Aliquam feugiat pharetra</h4>
                                        <ol>
                                            <li>lectus dolor</li>
                                            <li>penatibus et magnis</li>
                                            <li>Nam congue</li>
                                            <li> Nullam sit amet</li>
                                            <li>laoreet magna </li>
                                        </ol>
                                        <p>Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Sed vel mattis ipsum Nam congue lectus dolor, ac laoreet magna tincidunt sed. Nullam sit amet nulla vitae urna varius consectetur. Sed vitae magna consectetur, pharetra nisi ut, vestibulum purus. Nulla luctus nisl sed lectus consequat porttitor. Suspendisse id tempor nibh, id luctus nisi. Ut ultrices ante a imperdiet hendrerit. Curabitur dui mi, sagittis eu varius eu, tempor ornare quam. Donec quis vestibulum dui. Aliquam erat volutpat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Sed vel mattis ipsum.</p>

                                        <div className='flex sm:flex-row flex-col items-center w-full sm:gap-10 gap-0 mt-4 blogsingle-author'>
                                            <StaticImage src="../../assets/images/blogsingle-author.png" alt="" />
                                            <div className='flex flex-col sm:text-left text-center w-100'>
                                                <h6>author</h6>
                                                <h5>Jane Doe</h5>
                                                <span className="text-base">Nam congue lectus dolor, ac laoreet magna tincidunt sed. Nullam  c laoreet magna tincidunt sed. Nul sit amet nulla vitae urna </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
        </>
    )

}

export default BlogSinglepage